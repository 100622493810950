<style>
.bg-readonly input, .bg-readonly textarea {
    background-color: rgb(235, 235, 235);
}
.remove-button, .add-button {
  font-size: 1.5em;
  background-color: #ff5c5c; /* Red color for remove button */
  color: #fff; /* White text color for buttons */
  border: none;
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0.2em;
  border-radius: 5px;
}
.add-button {
  background-color: #5cb85c; /* Green color for add button */
}
</style>
<template>
    <vx-card :title="(this.data.id ? 'Edit' : 'Create') + ' Inbound - Customer Return Form'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Customer/Ship-to Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.customer_code" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>SO/Invoice Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.so_code" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales Return Data</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.sales_return_data " :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>GIR Data</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.gir_data " :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(data.receive_date) " :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Note</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea :readonly="statusReadonly" v-model="data.note" width="100%" :class="statusReadonly ? 'bg-readonly' : ''"/>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Attachment</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />

            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="this.data.fileUrl != ''">
            <div class="vx-col sm:w-1/3 w-full flex items-center">

            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <div class="op-block mt-5">
                    <vs-list>
                        <vs-list-item title="File 1">
                            <div class="vx-input-group flex">
                                <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                    title="Download" class="mr-1" @click="openTab(fileUrl)" />
                                <!-- <vs-button size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                    title="Delete" /> -->
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full mb-6">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="10%">SKU Code</th>
                            <th width="10%">SKU Description</th>
                            <th width="10%">HU</th>
                            <th width="5%">Return Qty</th>
                            <template v-if="data.shipment_number !== '' && data.shipment_number !== null">
                                <th width="5%">Pickup Qty</th>
                            </template>
                            <th width="5%">Qty Receive</th>
                            <th width="10%">ED</th>
                            <th width="10%">Batch</th>
                            <th width="10%">Checked Status</th>
                            <th width="10%">Qty Putaway</th>
                            <th width="15%">Suggested Warehouse Area</th>
                            <!-- <th width="5%"></th> -->
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.item_code" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.item_name" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.item_unit" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.quantity" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                        </td>
                        <template v-if="item.pickup_qty !== 0">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.pickup_qty" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                            </td>
                        </template>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.qty" :readonly="true" class="w-full bg-readonly"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <template v-if="item.expired_date == null">
                                <vs-input :value="item.expired_date" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </template>
                            <template v-else>
                                <vs-input :value="formatDate(item.expired_date)" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </template>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.batch" :readonly="true" class="w-full bg-readonly"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.checked_status" :readonly="true" class="w-full bg-readonly"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="number" v-model="item.qty_putaway" class="w-full"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <WarehouseArea :value="item.warehouse_area" :multiple="false"
                              :damaged="getDamagedStatus(item.checked_status)"
                              :warehouse-id="item.wh_id"
                              :base-url="`/api/wms/v1/master/warehouse-areas/status`"
                              label="name" track-by="name"
                              :select_options = "item.select_warehouse_area"
                              @selected="(v)=>{onSelect(index,v)}">
                          </WarehouseArea>
                        </td>
                        <!-- <td class="td vs-table--td" style="padding: 5px">
                          <button @click="removeItem(index)" v-if="index !== 0 && items[index].hideFields" class="remove-button">&#45;</button>
                          <button @click="addItem(index)" v-else class="add-button">&#43;</button>
                        </td> -->
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
          <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div
                style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-2 mb-3" @click="handleSubmit(false)">Submit</vs-button>
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(true)">Save and Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>

import _ from "lodash";
import WarehouseArea from "./component/WarehouseArea.vue";
import Date from "@/components/Date.vue";
import moment from "moment";

import vSelect from "vue-select";
export default {
    components: {
        WarehouseArea,
        Date,
        vSelect
    },
    data() {
        return {
            data: {
                id: null,
                customer_code: null,
                so_code: null,
                code: null,
                gir_number: null,
                sales_return_data: null,
                gir_data: null,
                receive_date: null,
                note: null,
                file: null,
                status: null,
                fileUrl: '',
                shipment_number: '',
              },
            items: [],
            warehouseAreaOptions: [],
            statusOptions: ['Passed', 'Damage'],
            statusReadonly: false,
        }
    },
    mounted() {
        // var now = moment()
        // this.data.receive_date = now
        // now.add(1, 'days')

        this.id = this.$route.params.id;
        if (this.id) { // sto id
            this.getData()
        }
    },
    methods: {
        getDamagedStatus(checkedStatus) {
            switch (checkedStatus) {
            case 'Good':
                return 0;
            case 'Bad':
                return 1;
            case 'Expired':
                return 2;
            default:
                // Handle other cases if needed
                return -1; // Or any other default value
            }
        },
      onSelect(i,v){
        // item.warehouse_area = v;
        this.items[i].warehouse_area = v.id
        this.items[i].select_options = {
          id: v.id,
          name: v.name
        }
        console.log(v)
      },
      addItem(index) {
          // Check if the current row has already added a new row
          if (!this.items[index].hideFields) {
            const newItem = {
              item_code: this.items[index].item_code,
              item_name: this.items[index].item_name,
              item_unit: this.items[index].item_unit,
              quantity: this.items[index].quantity,
              pickup_qty: this.items[index].pickup_qty,
              qty: this.items[index].qty,
              batch: this.items[index].batch,
              expired_date: this.items[index].expired_date,
              checked_status: this.items[index].checked_status,
              qty_putaway: '',
              warehouse_area: false,
              wh_id: this.items[index].wh_id,
              hideFields: true, // Set the flag to true for the current row
            };
            this.items.splice(index + 1, 0, newItem);
          }
        },
        removeItem(index) {
          this.items.splice(index, 1);
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.$store.state.inbound.customerReturn.baseUrlPath + "/putaway/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        console.log("RESP",resp)
                        var now = moment().toDate();
                        this.data.id = resp.data.sr_id
                        this.data.customer_code = resp.data.customer_code
                        this.data.so_code = resp.data.so_code
                        this.data.so_id = resp.data.so_id
                        this.data.sales_return_data = resp.data.sales_return_code + " " + resp.data.gir_number
                        this.data.gir_data = resp.data.code
                        this.data.code = resp.data.code
                        this.data.gir_number = resp.data.gir_number
                        this.data.receive_date = resp.data.receive_date == null ? now : resp.data.receive_date
                        this.data.note = resp.data.note
                        this.data.status = resp.data.status
                        this.data.srr_id = resp.data.srr_id
                        this.data.wh_id = resp.data.wh_id
                        this.data.shipment_number = resp.data.shipment_number
                        var sameitem = ""
                        console.log('linedata',resp.data.lines)
                        resp.data.lines.forEach(el =>{
                            let expired_date = null
                            let batch = ""
                            console.log('ed',el.expired_date)
                            if(el.expired_date == "0001-01-01T00:00:00Z"){
                                expired_date = null
                            } else {
                                expired_date = el.expired_date
                            }
                            if(el.batch == "0000"){
                                batch = ""
                            } else {
                                batch = el.batch
                            }
                          const newItem = {
                            item_code: el.item_code,
                            item_name: el.item_name,
                            item_unit: el.item_unit,
                            quantity: el.return_qty,
                            pickup_qty: el.pickup_qty,
                            qty: el.qty,
                            batch: batch,
                            expired_date: expired_date,
                            checked_status: el.checked_status,
                            qty_putaway: el.qty_putaway ? el.qty_putaway : el.qty,
                            warehouse_area: el.warehouse_area,
                            select_warehouse_area: {
                              id: el.warehouse_area,
                              name: '',
                            },
                            srp_id: el.srp_id,
                            sr_line_id: el.srrl_line_id,
                            wh_id: resp.data.wh_id,
                            hideFields: (sameitem == el.item_code + el.item_unit + el.return_qty) ? true : false
                          }
                          this.items.push(newItem)
                          sameitem = el.item_code + el.item_unit + el.return_qty
                        })
                        this.data.fileUrl = resp.data.file_url
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.inbound.customerReturn.baseRouterName,
            });
        },
        handleChangeAttachment(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        handleSubmit(isConfirm = false) {
            var proceed = true;
            try{
                const groupedByItemCode = this.items.reduce((accumulator, current) => {
                    const itemCode = current.item_code + " - " + current.item_unit;
                    accumulator[itemCode] = accumulator[itemCode] || { sum: 0, qty: 0 };
                    accumulator[itemCode].sum += parseInt(current.qty_putaway);
                    accumulator[itemCode].qty += current.qty;
                    return accumulator;
                }, {});

                for (const itemCode in groupedByItemCode) {
                    const { sum, qty } = groupedByItemCode[itemCode];
                    console.log("QTY",qty)                        
                    console.log("SUM",sum)                         
                    if (qty !== sum) {
                        const message = `quantity putaway of item with code ${itemCode} must be ${qty}`;
                        this.$vs.dialog({
                            type: 'alert',
                            color: 'danger',
                            title: 'Information',
                            text: message,
                            acceptText: 'OK',
                        });
                        proceed = false
                        throw new Error('BreakException');                            
                    }else{
                        proceed = true
                    }
                }

                console.log("tes warehouse",this.items)                        
                this.items.forEach((el, idx) => {  
                    if (this.items[idx].warehouse_area == null || this.items[idx].warehouse_area == 0) {
                        this.$vs.dialog({
                            type: 'alert',
                            color: 'danger',
                            title: 'Information',
                            text: 'Suggested Warehouse Area is required!',
                            acceptText: 'OK',
                        });
                        proceed = false
                        throw new Error('BreakException');
                    } else {
                        proceed = true
                    }
                });                                    
            }catch (e) {
                if (e.message !== 'BreakException') throw e;
            }
            if(proceed){
                if(isConfirm){
                    this.data.status = 3
                }
                this.data.receive_date = moment(this.data.receive_date).format('YYYY-MM-DD')
                console.log("tes date",this.data.receive_date)
                let message = ""
                this.items.forEach((val, i) => {
                this.items[i].expired_date = moment(val.expired_date).format('YYYY-MM-DD')
                this.items[i].qty_putaway = parseInt(this.items[i].qty_putaway)
                })

                let formData = new FormData();
                console.log(this.items)
                formData.append("file", this.file);
                formData.append("data", JSON.stringify(this.data))
                formData.append("lines", JSON.stringify(this.items))
                this.$vs.loading();
                let http = null
                console.log('tes',this.items[0].srp_id)
                if (this.items[0].srp_id != null) {
                    http = this.$http.put(this.$store.state.inbound.customerReturn.baseUrlPath + "/edit-putaway", formData)
                } else {
                    http = this.$http.post(this.$store.state.inbound.customerReturn.baseUrlPath + "/save-putaway", formData)
                }

                http.then((resp) => {
                    console.log('debug',resp)
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });

                        this.$vs.loading.close();
                        this.draw++;
                        this.handleBack()
                    } else {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                            duration: 10000,
                        });
                    }
                })
            }
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
    },
    watch: {
    }
}
</script>
